import React, {createRef, Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import {useConfig} from "../hooks/use-config";
import {useAppDispatch, useAppSelector} from "../hooks/redux-hooks";
import {setShowConfigModal} from "../state/config-slice";

type ConfigModalProps = {}

export const ConfigModal = (_props: ConfigModalProps) => {
    const [ipAddress, setIpAddress] = useState("");
    const isOpen = useAppSelector(state => state.config.showConfigModal);
    const {config, setServerIp, isLoading} = useConfig();
    const dispatch = useAppDispatch();

    const cancelButtonRef = createRef<HTMLButtonElement>();

    const showConfigModal = (isOpen: boolean) => {
        dispatch(setShowConfigModal(isOpen))
    }

    const handleClose = () => {
        if(config.serverIp) {
            showConfigModal(false);
        }
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIpAddress(e.target.value);
    }

    const handleSetServerIp = () => {
        setServerIp(ipAddress);
        showConfigModal(false);
    }

    useEffect(() => {
        if(!config.serverIp && !isLoading) {
            setShowConfigModal(true);
        }
    }, [config.serverIp, isLoading])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <h3 className="text-xl font-bold tracking-tight sm:text-2xl">
                                    Set server IP
                                </h3>
                                <div className="mt-2">
                                    <input
                                        type="text" pattern="d\*"
                                        name="server-ip"
                                        id="server-ip"
                                        className="block w-full rounded-md border-0 mb-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="10.0.1.101"
                                        onChange={handleTextChange}
                                        defaultValue={config.serverIp}
                                    />
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={handleSetServerIp}
                                    >
                                        Set server address
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
