import {useCallback, useEffect, useState} from "react";
import {Config} from "../types";
import {useAppDispatch, useAppSelector} from "./redux-hooks";
import {setServerIP} from "../state/config-slice";

export const useConfig = () => {
    const [isLoading, setIsLoading] = useState(true);
    const config = useAppSelector(state => state.config);
    const dispatch = useAppDispatch();

    const fetchLocalConfig = useCallback(() => {
        const rawConfig = localStorage.getItem("config");
        if(rawConfig) {
            const config: Config = JSON.parse(rawConfig);
            if(config.serverIp) {
                dispatch(setServerIP(config.serverIp));
            }
        }
        setIsLoading(false);
    }, [dispatch]);

    const setServerIp = (serverIp: string) => {
        const newConfig: Config = {
            ...config,
            serverIp,
        }
        localStorage.setItem("config", JSON.stringify(newConfig));
        dispatch(setServerIP(serverIp))
    }

    useEffect(() => {
        fetchLocalConfig();
    }, [fetchLocalConfig])

    return {
        isLoading,
        config,
        setServerIp,
    }
}
