import React, {Fragment, useState} from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../utils";
import {Game} from "../../../types";

type RuntimeOption = {
    time: number;
    name: string;
}

const runtimes: RuntimeOption[] = [
    {time: 20, name: "20 mins"},
    {time: 30, name: "30 mins"},
    {time: 40, name: "40 mins"},
    {time: 0, name: "No timer"},
]

export type RuntimeSelectProps = {
    onNext: (runtime: number) => void;
    onBack: () => void;
    game?: Game;
}

export const RuntimeSelect = (props: RuntimeSelectProps) => {

    const [selectedRuntime, setSelectedRuntime] = useState<RuntimeOption>(runtimes[2]);

    const handleNext = () => {
        props.onNext(selectedRuntime.time*60);
    }

    const handleBack = () => {
        props.onBack();
    }

    return (
        <>
            <div className="md:flex md:items-center md:justify-between">
                <div className="min-w-0 flex-1 text-right sm:text-center">
                    <h2 className="text-2xl font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">
                        Start game
                    </h2>
                </div>
            </div>
            <h2 className="mt-6 text-lg text-center font-bold text-white">{props.game?.teamNameOne} vs {props.game?.teamNameTwo}</h2>
            <Listbox defaultValue={selectedRuntime} onChange={setSelectedRuntime}>
                {({open}) => (
                    <>
                        <div className="relative mt-4">
                            <Listbox.Button
                                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">{selectedRuntime?.name ?? "Select runtime"}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                  </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {runtimes?.map((runtimeOption) => (
                                        <Listbox.Option
                                            key={runtimeOption.time}
                                            className={({active}) =>
                                                classNames(
                                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={runtimeOption}
                                        >
                                            {({selected, active}) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                      {runtimeOption.name}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
            <button
                type="button"
                className={classNames(
                    'bg-indigo-600 hover:bg-indigo-500',
                    "mt-4 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
                onClick={handleNext}
            >
                Start game
            </button>
            <button
                type="button"
                className="bg-red-600 hover:bg-red-500 mt-4 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleBack}
            >
                Back
            </button>
        </>
    );
};
