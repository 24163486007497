import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyDWVW7UxmgO23aOar-jsU0Ir9cXDNf0rnM',
  authDomain: 'townbeachdev.firebaseapp.com',
  databaseURL: 'https://townbeachdev.firebaseio.com',
  projectId: 'townbeachdev',
  storageBucket: 'townbeachdev.appspot.com',
  messagingSenderId: '117693968038',
  appId: '1:117693968038:web:bb52fe392f29e6fd5e4e67',
};

export const initialiseFirebase = () => {
  initializeApp(firebaseConfig);
  return getDatabase();
};
