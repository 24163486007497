import React, {
  memo,
  useEffect,
  useState,
  RefObject,
  forwardRef,
  RefAttributes,
  MutableRefObject,
  useContext, Dispatch
} from 'react';
import Countdown, {CountdownTimeDelta} from 'react-countdown';
import {Game} from "../../../types";
import {classNames} from "../../../utils";
import {ClientContext} from "../../../state/client-context";

type TimerProps = {
  game: Game;
  onTogglePause: (remaining: number) => void;
  ref: RefObject<Countdown>;
  setGame: Dispatch<React.SetStateAction<Game | undefined>>;
};

const propsAreEqual = (prevProps: Readonly<Omit<TimerProps, "ref"> & RefAttributes<Countdown>>, nextProps: Readonly<Omit<TimerProps, "ref"> & RefAttributes<Countdown>>) => {
  return (
    prevProps.game.paused === nextProps.game.paused &&
    prevProps.game.runtime === nextProps.game.runtime &&
    prevProps.game.timeRemaining === nextProps.game.timeRemaining
  );
};

const zeroPadded = (num: number) => {
  return num < 10 ? `0${num}` : num;
};

const getTimeInSecondsFuture = (x: number) => {
  const currentDate = new Date();
  return new Date(currentDate.getTime() + x * 1000);
};

export const Timer = forwardRef<Countdown, TimerProps>((props, ref) => {
  const { game, setGame } = props;
  const {client, config} = useContext(ClientContext);
  const [isCompleted, setIsCompleted] = useState(false);

  const formattedRef = ref as MutableRefObject<Countdown | null>
  const [halfTimeFired, setHalftimeFired] = useState(false);
  const [fullTimeFired, setFullTimeFired] = useState(false);

  const handleTick = (timeDelta: CountdownTimeDelta) => {
    if (!halfTimeFired && Math.floor((timeDelta.total-1000)/1000) < (game.runtime?? 1)/2) {
      setGame(prev => ({
        ...prev,
        timeRemaining: (formattedRef.current?.state.timeDelta.total?? 1) / 1000
      }))
      setHalftimeFired(true);
      formattedRef.current?.pause();
      props.onTogglePause(Math.floor(formattedRef.current?.state.timeDelta.total?? 1)/1000);
      client?.publish(`${config?.connectionName}/buzzer`, JSON.stringify({}))
    }
  }

  useEffect(() => {
    if(isCompleted && !fullTimeFired && client && config?.connectionName) {
      setFullTimeFired(true);
      client?.publish(`${config?.connectionName}/buzzer`, JSON.stringify({}))
    }
  }, [isCompleted, fullTimeFired, config, client])

  useEffect(() => {
    if (game.paused && formattedRef) {
      formattedRef?.current?.pause();
    } else if (formattedRef != null) {
      formattedRef.current?.start();
    }
  }, [game.paused, formattedRef]);

  return (
    <>
      <div className="timer">
        <Countdown
          ref={ref}
          onComplete={() => {
            setIsCompleted(true);
          }}
          precision={2}
          intervalDelay={35}
          onTick={handleTick}
          renderer={(renderProps) => {
            if (isCompleted) {
              return <div className="game-over">Game over</div>;
            }
            const { minutes, seconds, milliseconds } = renderProps;
            if(props.game?.paused) {
              return "Paused"
            }
            if (minutes === Math.floor((game.runtime ?? 0) / 120)) {
              return (
                <div className="yellow-time">
                  <span className="first-num">{zeroPadded(minutes)}</span>:
                  <span className="second-num">{zeroPadded(seconds)}</span>
                </div>
              );
            }
            if (minutes === 0) {
              return (
                <div className="red-time">
                  <span className="first-num">{zeroPadded(seconds)}</span>:
                  <span className="second-num">
                    {zeroPadded(milliseconds / 10)}
                  </span>
                </div>
              );
            }

            return (
              <div>
                <span className="first-num">{zeroPadded(minutes)}</span>:
                <span className="second-num">{zeroPadded(seconds)}</span>
              </div>
            );
          }}
          date={getTimeInSecondsFuture(game.timeRemaining ?? 0)}
        />
      </div>
      <button
        type="button"
        className={classNames(
          'bg-indigo-600 hover:bg-indigo-500',
          "mt-4 w-32 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        )}
        onClick={() => {
          if(props.game?.paused && client) {
            formattedRef.current?.start();
            props.onTogglePause(Math.floor(formattedRef.current?.state.timeDelta.total?? 1)/1000);
            client.publish(`${config?.connectionName}/buzzer`, JSON.stringify({}));
          } else {
            // eslint-disable-next-line no-restricted-globals
            const acceptedConfirm = confirm("Pause game?")
            if(acceptedConfirm) {
              formattedRef.current?.pause();
              props.onTogglePause(Math.floor(formattedRef.current?.state.timeDelta.total?? 1)/1000);
            }
          }
        }}
      >
        {props.game?.paused? "Resume" : "Pause"}
      </button>
    </>
  );
});

export default memo(Timer, propsAreEqual);
