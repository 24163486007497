import React, {useContext, useEffect, useState} from "react";
import {ClientContext} from "../../state/client-context";

export type ConnectedLightProps = {
    onClick: () => void;
};

export const ConnectedLight = (props: ConnectedLightProps) => {

    const {client, config} = useContext(ClientContext);
    const [isConnected, setIsConnected] = useState(false);

    const handleBuzzer = () => {
        if(client) {
            client.publish(`${config?.connectionName}/buzzer2`, "")
        }
    }

    useEffect(() => {
      if(client) {
        client.on('connect', () => {
          setIsConnected(true);
        });
        client.on('reconnect', () => {
          setIsConnected(false);
        });
        client.on('disconnect', () => {
          setIsConnected(false);
        });
        client?.on('end', () => {
          setIsConnected(false);
        });
      }
    }, [client])

    if(isConnected) {
        return (
          <>
            <div className="flex flex-row items-center justify-center" onClick={props.onClick}>
                <div className="led-green"></div>
            </div>
              <button
                type="button"
                className="bg-white ml-2 mt-4 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                onClick={handleBuzzer}
              >
                🔔
              </button>
          </>
        );
    } else {
        return (
            <div className="flex flex-row items-center justify-center" onClick={props.onClick}>
                <div className="led-red"></div>
            </div>
        );
    }
};
