import { Database, onValue, ref } from 'firebase/database';
import { BehaviorSubject, Observable } from 'rxjs';
import {Team} from "../types";

export const watchTeams = (
  firebaseApp: Database,
  // eslint-disable-next-line no-undef
): Observable<Record<string, Team>> => {
  const teamsSubject = new BehaviorSubject<Record<string, Team>>({
    xx1: {
      avaliability: {
        day: '',
      },
      uid: 'xx1',
      captain: '',
      display_only: false,
      grade: 0,
      points: 0,
      team_name: 'Team 1',
      players: [],
    },
    xx2: {
      avaliability: {
        day: '',
      },
      uid: 'xx2',
      captain: '',
      display_only: false,
      grade: 0,
      points: 0,
      team_name: 'Team 2',
      players: [],
    },
  });
  const teamsSubscription = teamsSubject.asObservable();
  const teamsRef = ref(firebaseApp, 'teams');
  onValue(teamsRef, (snapshot) => {
    const data: Record<string, Team> = snapshot.val();
    data.xx1 = {
      avaliability: {
        day: '',
      },
      uid: 'xx1',
      captain: '',
      display_only: false,
      grade: 0,
      points: 0,
      team_name: 'Team 1',
      players: [],
    };
    data.xx2 = {
      avaliability: {
        day: '',
      },
      uid: 'xx2',
      captain: '',
      display_only: false,
      grade: 0,
      points: 0,
      team_name: 'Team 2',
      players: [],
    };
    teamsSubject.next(data);
  });
  return teamsSubscription;
};
