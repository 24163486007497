import { Database } from "firebase/database";
import React, {FC, useEffect, useState} from "react";
import { createContext } from "react";
import {MqttClient} from "precompiled-mqtt";
import {Config} from "./types";
import useClient from "./use-client";
import {Team} from "../types";
import {initialiseFirebase} from "./initialise-firebase";
import {watchTeams} from "./watch-teams";

type ClientContextType = {
    client?: MqttClient;
    config?: Config;
    setConfig: (config: Config) => void;
    teams: Record<string, Team>;
    firebaseStore?: Database;
};
export const ClientContext = createContext<ClientContextType>({
    setConfig: () => {},
    teams: {}
});

export type ClientContextProps = {
    children: React.ReactNode;
}

const SETTINGS_STORAGE_KEY = 'au.com.volley.controller_settings';

export const ClientContextWrapper:FC<ClientContextProps> = (props) => {
    const [config, setConfig] = useState<Config | undefined>();
    const [firebaseStore, setFirebaseStore] = useState<Database | undefined>();
    const mqtt = useClient(config?.connectionName, config?.connectionPassword, config?.screenNumber);
    const [teams, setTeams] = useState<Record<string, Team>>({});

    const loadSettingsFromStorage = () => {
        const rawSetting = localStorage.getItem(SETTINGS_STORAGE_KEY);
        if (rawSetting) {
            const storedConfig = JSON.parse(rawSetting) as Config;
            setConfig(storedConfig);
        }
    };

    useEffect(() => {
        loadSettingsFromStorage();
        const firebaseDB = initialiseFirebase();
        setFirebaseStore(firebaseDB);
        const teamsSubscription = watchTeams(firebaseDB);
        teamsSubscription.subscribe((subbedTeams) => setTeams(subbedTeams));
    }, []);

    return (
        <ClientContext.Provider value={{
            client: mqtt.client,
            config: config,
            setConfig,
            teams,
            firebaseStore,
        }}>
            {props.children}
        </ClientContext.Provider>
    );
};
