import React, {useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {Game, Team} from "../../types";
import {ClientContext} from "../../state/client-context";
import {TeamSelect} from "./team-select/team-select";
import {RuntimeSelect} from "./runtime-select/runtime-select";
import {InGame} from "./in-game/in-game";
import { ref, push } from "firebase/database";

export type GameProps = {}

type GameState = "select-teams" | "select-runtime" | "in-game";
const GAME_STORAGE_KEY = 'au.com.volley.controller_game';

export const GameComponent = (_props: GameProps) => {

    const [game, setGame] = useState<Game | undefined>();
    const {client, config, firebaseStore} = useContext(ClientContext);
    const [loadingFromStorage, setLoadingFromStorage] = useState(true);

    const handleSetGame = (game: Game | undefined) => {
        const gameString = JSON.stringify(game);
        localStorage.setItem(GAME_STORAGE_KEY, gameString);
    }

    useEffect(() => {
        if(!loadingFromStorage) {
            handleSetGame(game)
        }
    }, [game, loadingFromStorage])

    const gameMode: GameState = useMemo(() => {
        if(game) {
            if(game.teamIdOne && game.teamIdTwo && game.runtime !== undefined) {
                return "in-game";
            }
            if(game.teamIdOne && game.teamIdTwo && game.runtime === undefined) {
                return "select-runtime";
            }
        }

        return "select-teams";
    }, [game])

    const handleSetTimeAndStart = (time: number) => {
        if(client) {
            const updatedGame: Game = {
                ...game,
                runtime: time,
                timeRemaining: time,
                scoreOne: 0,
                scoreTwo: 0,
            }
            setGame(updatedGame)
            client.publish(`${config?.connectionName}/buzzer`, JSON.stringify({}));
            client.publish(`${config?.connectionName}/screen/${config?.screenNumber}`, JSON.stringify(updatedGame), {qos: 2, retain: true});
        }
    }

    const handleSelectTeams = ({team_one, team_two}: {team_one: Team, team_two: Team}) => {
        const updatedGame: Game = {
            teamIdOne: team_one.uid,
            teamIdTwo: team_two.uid,
            teamNameOne: team_one.team_name,
            teamNameTwo: team_two.team_name,
        }
        setGame(updatedGame);
        if(client) {
            client.publish(`${config?.connectionName}/screen/${config?.screenNumber}`, JSON.stringify(updatedGame), {qos: 2, retain: true})
        }
    }

    const handleSetScore = (team_number: number, score: number, remainingTime: number) => {
        let updatedGame: Game = {}
        if(team_number === 0) {
            updatedGame = {
                ...game,
                scoreOne: score,
                timeRemaining: remainingTime,
            }
        } else {
            updatedGame = {
                ...game,
                scoreTwo: score,
                timeRemaining: remainingTime,
            }
        }
        setGame(updatedGame)
        if(client) {
            client.publish(`${config?.connectionName}/screen/${config?.screenNumber}`, JSON.stringify(updatedGame), {qos: 2, retain: true})
        }
    }

    const handlePauseToggle = (remainingTime: number) => {
        if(client) {
            if(game?.paused) {
                const updatedGame: Game = {
                    ...game,
                    paused: false,
                    timeRemaining: remainingTime,
                }
                setGame(updatedGame)
                client.publish(`${config?.connectionName}/screen/${config?.screenNumber}`, JSON.stringify(updatedGame), {qos: 2, retain: true})
            } else {
                const updatedGame: Game = {
                    ...game,
                    paused: true,
                    timeRemaining: remainingTime,
                }
                setGame(updatedGame)
                client.publish(`${config?.connectionName}/screen/${config?.screenNumber}`, JSON.stringify(updatedGame), {qos: 2, retain: true})
            }
        }
    }

    const loadGameFromStorage = () => {
        const loadedGame = localStorage.getItem(GAME_STORAGE_KEY);
        if (loadedGame !== null && loadedGame !== undefined && loadedGame !== "undefined") {
            const game = JSON.parse(loadedGame) as Game;
            setGame(game);
        }
        setLoadingFromStorage(false);
    }

    const handleEndGame = async () => {
        if(firebaseStore) {
            try {
                await push(ref(firebaseStore, 'completedDaily/' + getDateAsString()), {
                    team_one: {
                        team_id: game?.teamIdOne,
                        team_name: game?.teamNameOne,
                        team_score: game?.scoreOne,
                    },
                    team_two: {
                        team_id: game?.teamIdTwo,
                        team_name: game?.teamNameTwo,
                        team_score: game?.scoreTwo,
                    },
                });
            } catch (e) {
                console.error(e);
            }
            setGame({});
            if(client) {
                client.publish(`${config?.connectionName}/screen/${config?.screenNumber}`, JSON.stringify({}), {qos: 2, retain: true})
            }
        }
    }

    const getDateAsString = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        return `${year}${month}${day}`;
    }

    const handleTimeSelectBack = () => {
        const updatedGame: Game = {
            ...game,
            teamNameOne: undefined,
            teamNameTwo: undefined,
            teamIdOne: undefined,
            teamIdTwo: undefined
        }
        setGame(updatedGame)
        if(client) {
            client.publish(`${config?.connectionName}/screen/${config?.screenNumber}`, JSON.stringify(updatedGame), {qos: 2, retain: true})
        }
    }

    useEffect(() => {
        if(!game) {
            loadGameFromStorage();
        }
    }, [])

    if(config?.screenNumber === undefined) {
        return null;
    }


    return (
        <div style={{margin: "0 auto"}}>
            {gameMode === "select-teams" && (
                <TeamSelect onNext={handleSelectTeams} court={config.screenNumber} game={game} />
            )}
            {gameMode === "select-runtime" && (
                <RuntimeSelect game={game} onBack={handleTimeSelectBack} onNext={handleSetTimeAndStart} />
            )}
            {gameMode === "in-game" && (
                <InGame setGame={setGame} onSafeExit={() => setGame(undefined)} onEnd={handleEndGame} onTogglePause={handlePauseToggle} game={game} handleSetScore={handleSetScore} />
            )}
        </div>
    );
};
