import { useEffect, useState } from 'react';
import { connect, MqttClient } from 'precompiled-mqtt';

const useClient = (username?: string, password?: string, screenId?: number) => {
  const [client, setClient] = useState<MqttClient | undefined>();

  useEffect(() => {
    if (client) {
      client.end();
    }
    const mqttClient = connect(`mqtt://mqtt.townbeach.com.au:8443`, {
      clientId: String(screenId),
      username,
      password,
      protocol: 'wss',
    });
    setClient(mqttClient);
    return () => {
      mqttClient.end();
    };
  }, [password, username]);

  return { client };
};

export default useClient;
