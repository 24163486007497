import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

interface ConfigState {
    serverIp?: string,
    showConfigModal: boolean,
}

const initialState: ConfigState = {
    showConfigModal: false,
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setServerIP: (state, action: PayloadAction<string>) => {
            state.serverIp = action.payload
        },
        setShowConfigModal: (state, action: PayloadAction<boolean>) => {
            state.showConfigModal = action.payload
        },
    },
})

export const { setServerIP, setShowConfigModal } = configSlice.actions;

export const selectServerIp = (state: RootState) => state.config

export default configSlice.reducer
