import React, {Fragment, useContext, useMemo, useState} from 'react';
import {Game, Team} from "../../../types";
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../utils";
import {ClientContext} from "../../../state/client-context";

export type TeamSelectProps = {
    onNext: ({team_one, team_two}: {team_one: Team, team_two: Team}) => void;
    court: number;
    game?: Game;
}

export const TeamSelect = (props: TeamSelectProps) => {

  const { teams } = useContext(ClientContext);
    const sortedTeams = Object.values(teams?? {}).map(team => team).sort((a, b) => a.team_name.localeCompare(b.team_name))

    const [selectedTeam1, setSelectedTeam1] = useState<Team | undefined>();
    const [selectedTeam2, setSelectedTeam2] = useState<Team | undefined>();

    const continueDisabled = useMemo(() => {
        return !(selectedTeam1 && selectedTeam2);
    }, [selectedTeam1, selectedTeam2]);

    const handleNext = () => {
        if(selectedTeam1 && selectedTeam2) {
            props.onNext({team_one: selectedTeam1, team_two: selectedTeam2});
        }
    }

    return (
        <>
            <div className="md:flex md:items-center md:justify-between">
                <div className="min-w-0 flex-1 text-right sm:text-center">
                    <h2 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
                        Select teams
                    </h2>
                </div>
            </div>
            <h3 className="text-md font-bold text-white mb-0 mt-4">Team 1</h3>
            <Listbox disabled={!sortedTeams.length} defaultValue={selectedTeam1} onChange={team => {
                setSelectedTeam1(team);
            }}>
                {({open}) => (
                    <>
                        <div className="relative mt-2">
                            <Listbox.Button
                                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">{!sortedTeams.length? "Loading" : selectedTeam1?.team_name ?? "Select team"}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                  </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {sortedTeams.map((team) => (
                                        <Listbox.Option
                                            key={team.uid}
                                            className={({active}) =>
                                                classNames(
                                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={team}
                                        >
                                            {({selected, active}) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                      {team.team_name}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
            <h3 className="text-md font-bold text-white mb-0 mt-4">Team 2</h3>
            <Listbox disabled={!sortedTeams} defaultValue={selectedTeam2} onChange={team => {
                setSelectedTeam2(team);
            }}>
                {({open}) => (
                    <>
                        <div className="relative mt-2">
                            <Listbox.Button
                                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">{!sortedTeams.length? "Loading" : selectedTeam2?.team_name ?? "Select team"}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                  </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {sortedTeams.map((team) => (
                                        <Listbox.Option
                                            key={team.uid}
                                            className={({active}) =>
                                                classNames(
                                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={team}
                                        >
                                            {({selected, active}) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                      {team.team_name}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
            <button
                type="button"
                disabled={continueDisabled}
                className={classNames(
                    continueDisabled ? 'bg-gray-500 hover:bg-gray-500' : 'bg-indigo-600 hover:bg-indigo-500',
                    "mt-4 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
                onClick={handleNext}
            >
                Continue
            </button>
        </>
    );
};
