import React, {createRef, Dispatch, useState} from 'react';
import {Game} from "../../../types";
import {classNames} from "../../../utils";
import Timer from "./timer";
import Countdown from "react-countdown";

export type InGameProps = {
    handleSetScore: (team_number: number, score: number, timeRemaining: number) => void;
    game?: Game;
    onTogglePause: (remaining: number) => void;
    onEnd: () => void;
    onSafeExit: () => void;
    setGame: Dispatch<React.SetStateAction<Game | undefined>>;
}

export const InGame = (props: InGameProps) => {
    const [sidesSwitched, setSidesSwitched] = useState(false);
    const timerRef = createRef<Countdown>();

    const score = (increase: number, team_number: number) => {
        if(team_number === 0) {
            props.handleSetScore(team_number, (props.game?.scoreOne?? 0) + increase, (timerRef.current?.state.timeDelta.total??1)/1000);
        } else {
            props.handleSetScore(team_number, (props.game?.scoreTwo?? 0) + increase, (timerRef.current?.state.timeDelta.total??1)/1000);
        }
    }

    if(props.game === undefined) {
        return null;
    }

    return (
        <div className="flex flex-row justify-between h-full">
            <div className={classNames((sidesSwitched? "text-brandblue" : "text-brandyellow"),`font-bold flex flex-col justify-center items-center w-48`)}>
                {sidesSwitched ? props.game?.teamNameTwo : props.game?.teamNameOne}
                <div className="text-white text-5xl">
                    {sidesSwitched ? props.game?.scoreTwo?? 0 : props.game?.scoreOne?? 0}
                </div>
                <button
                    type="button"
                    className={classNames(
                      (sidesSwitched? "bg-brandblue" : "bg-brandyellow"),
                        `mt-4 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`, sidesSwitched? "focus-visible:outline-brandblue" : "outline-brandyellow"
                    )}
                    onClick={() => score(1, sidesSwitched? 1 : 0)}
                >
                    +1
                </button>
                <button
                    type="button"
                    disabled={((sidesSwitched ? props.game?.scoreTwo : props.game?.scoreOne)?? 0) === 0}
                    className={classNames(
                        ((sidesSwitched ? props.game?.scoreTwo : props.game?.scoreOne)?? 0) === 0? 'bg-gray-600' : (sidesSwitched? "bg-brandblue" : "bg-brandyellow"),
                        `mt-4 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`, sidesSwitched? "focus-visible:outline-brandblue" : "outline-brandyellow"
                    )}
                    onClick={() => score(-1, sidesSwitched? 1 : 0)}
                >
                    -1
                </button>
            </div>
            <div className="flex flex-col justify-center items-center text-white">
                <Timer setGame={props.setGame} onTogglePause={props.onTogglePause} game={props.game} ref={timerRef} />
                <button
                    type="button"
                    className={classNames(
                        'bg-indigo-600 hover:bg-indigo-500',
                        "mt-4 w-32 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                    onClick={() => {
                        props.setGame(prev => ({
                            ...prev,
                            timeRemaining: (timerRef.current?.state.timeDelta.total?? 1)/1000
                        }))
                        setSidesSwitched(prev => {
                            return !prev
                        })
                    }}
                >
                    Switch sides
                </button>
                <button
                    type="button"
                    className={classNames(
                        'bg-red-600 hover:bg-red-500',
                        "mt-4 w-32 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                    onClick={() => {
                        // eslint-disable-next-line no-restricted-globals
                        const acceptedConfirm = confirm("Are you sure you want to end the game?")
                        if(acceptedConfirm) {
                            props.onEnd();
                        }
                    }}
                >
                    End game
                </button>
            </div>
            <div className={classNames((sidesSwitched? "text-brandyellow" : "text-brandblue"),`flex font-bold flex-col justify-center items-center w-48`)}>
                {sidesSwitched ? props.game?.teamNameOne : props.game?.teamNameTwo}
                <div className="text-white text-5xl">
                    {sidesSwitched ? props.game?.scoreOne?? 0 : props.game?.scoreTwo?? 0}
                </div>
                <button
                    type="button"
                    className={classNames(
                      (sidesSwitched? "bg-brandyellow" : "bg-brandblue"),
                        `mt-4 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`, sidesSwitched? "focus-visible:outline-brandyellow" : "outline-brandblue"
                    )}
                    onClick={() => score(1, sidesSwitched? 0 : 1)}
                >
                    +1
                </button>
                <button
                    type="button"
                    disabled={((sidesSwitched ? props.game?.scoreOne : props.game?.scoreTwo)?? 0) === 0}
                    className={classNames(
                        ((sidesSwitched ? props.game?.scoreOne : props.game?.scoreTwo)?? 0) === 0? 'bg-gray-600' : (sidesSwitched? "bg-brandyellow" : "bg-brandblue"),
                        `mt-4 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`, sidesSwitched? "focus-visible:outline-brandyellow" : "outline-brandblue"
                    )}
                    onClick={() => score(-1, sidesSwitched? 0 : 1)}
                >
                    -1
                </button>
            </div>
        </div>
    );
};
