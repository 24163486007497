import React, {useContext, useState} from 'react';
import {GameComponent} from "./components/game/game";
import {ConfigModal} from "./modals/config-modal";
import {ClientContext} from "./state/client-context";
import {Loader} from "./components/loader";
import {ConnectedLight} from "./components/game/connected-light";
import { Config } from './state/types';
import SettingsModal from "./components/settings-modal";

const SETTINGS_STORAGE_KEY = 'au.com.volley.controller_settings';

function App() {

    const { config, client, teams, setConfig} = useContext(ClientContext);
    const [settingsIsOpen, setSettingsIsOpen] = useState(false);

  const setSettingsInStorage = (newConfig: Config | undefined) => {
    if (newConfig) {
      const storedConfig = JSON.stringify(newConfig);
      localStorage.setItem(SETTINGS_STORAGE_KEY, storedConfig);
      setConfig(newConfig);
      setSettingsIsOpen(false);
    } else {
      localStorage.removeItem(SETTINGS_STORAGE_KEY);
    }
  };

  return (
      <>
          <ConfigModal />
          <div className="h-full isolate bg-gray-900 px-6 sm:px-16 justify-center lg:gap-x-20 lg:px-24 lg:pt-0 pt-6">
              <div className="absolute mt-2 left-0">
                  <ConnectedLight onClick={() => setSettingsIsOpen(true)}/>
                  {settingsIsOpen && (
                    <SettingsModal
                      initialConfig={config}
                      onSave={setSettingsInStorage}
                      onClose={() => setSettingsIsOpen(false)}
                    />
                  )}
              </div>
              {!client || client?.reconnecting || client?.disconnected || Object.keys(teams).length === 0? (
                  <Loader client={client} />
              ) : (
                <div>
                  <GameComponent />
                </div>
              )}
          </div>
      </>
  );
}

export default App;
