import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Config } from '../state/types';

type SettingsModalProps = {
  initialConfig: Config | undefined;
  onClose: () => void;
  onSave: (config: Config | undefined) => void;
};

function SettingsModal(props: SettingsModalProps) {
  const { initialConfig, onClose, onSave } = props;
  const [config, setConfig] = useState<Config | undefined>(initialConfig);

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
              type="text"
            defaultValue={config?.connectionName}
            mt={2}
            placeholder="Connection name"
            onChange={(val) =>
              setConfig((prev) => ({
                ...prev,
                connectionName: val.target.value,
              }))
            }
          />
          <Input
            mt={2}
            defaultValue={config?.connectionPassword}
            placeholder="Connection password"
            type="password"
            onChange={(val) =>
              setConfig((prev) => ({
                ...prev,
                connectionPassword: val.target.value,
              }))
            }
          />
          <NumberInput defaultValue={config?.screenNumber}>
            <NumberInputField
              mt={2}
              placeholder="Screen number (Kokomo=0, Bondi=1)"
              onChange={(val) =>
                setConfig((prev) => ({
                  ...prev,
                  screenNumber: Number(val.target.value),
                }))
              }
            />
          </NumberInput>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => onSave(config)}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SettingsModal;
